import { appsetting } from "../configs/appSettings";
import { aurorasit } from "../configs/appSettings.aurorasit";
import { dev } from "../configs/appSettings.dev";
import { prod } from "../configs/appSettings.prod";
import { sit } from "../configs/appSettings.sit";
import { uat } from "../configs/appSettings.uat";
import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";
import { Environments } from "./environment";

export const appSettings = (): IPartnerAppSettings => {
  let environmentAppSetting: IPartnerAppSettings;
  switch (window["FxpAppSettings"].EnvironmentName) {
    case Environments.Dev:
      environmentAppSetting = dev;
      break;
    case Environments.Sit:
      environmentAppSetting = sit;
      break;
    case Environments.Uat:
      environmentAppSetting = uat;
      break;
    case Environments.AuroraSit:
      environmentAppSetting = aurorasit;
      break;
    case Environments.Prod:
      environmentAppSetting = prod;
      break;
    default:
      environmentAppSetting = dev;
      break;
  }

  // properties in later spreads “win out” over previously created properties.
  const mergedSettings = mergeObjects(appsetting, environmentAppSetting);

  return mergedSettings as IPartnerAppSettings;
};

const mergeObjects = (destination: object, ...sources: object[]): object => {
  const sourceLength = sources.length;
  for (let objIndex = 0; objIndex < sourceLength; objIndex++) {
    const source = sources[objIndex];
    if (typeof source === "object") {
      Object.keys(source).forEach(key => {
        const prop = source[key];
        if (typeof prop === "object") {
          // eslint-disable-next-line no-constant-condition
          if (!typeof (destination[key] === "object")) {
            destination[key] = Array.isArray(prop) ? [] : {};
          }
          mergeObjects(destination[key], prop);
        } else {
          destination[key] = prop;
        }
      });
    }
  }
  return destination;
};
