import {
  IAppService,
  IStateConfig,
  IFxpAppContext,
  IServiceEndPoints,
  IRouteInfo,
  IPartnerBundle,
  ISharedComponents,
  ISharedComponentConfig,
  ComponentFramework,
  CommonUtils
} from "@fxp/fxpservices";
import { appSettings } from "./environments/common/appsettingsGenerator";
import { StateParams } from "@uirouter/core";
import { PjmRoutes } from "./app/common/application.constants";
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class SampleApplication_routes implements IAppService {

  getSharedComponentsInfo(): ISharedComponents {
    const decoValidatorComponent: ISharedComponentConfig = {
      componentName: "sampleapplication-decovalidatorcomponent", // Unique name of component in format <appname>.<componentname> in lower case.
      exposeAsExternalComponents: false, // Setting it to true will make this component as external component. Keep it false if you want to consume only in context of FxP.
      componentFramework: ComponentFramework.React
    }

    const recoValidatorComponent: ISharedComponentConfig = {
      componentName: "sampleapplication-recovalidatorcomponent", // Unique name of component in format <appname>.<componentname> in lower case.
      exposeAsExternalComponents: false, // Setting it to true will make this component as external component. Keep it false if you want to consume only in context of FxP.
      componentFramework: ComponentFramework.React
    }

    const stateReversalComponent: ISharedComponentConfig = {
      componentName: "sampleapplication-statereversalcomponent", // Unique name of component in format <appname>.<componentname> in lower case.
      exposeAsExternalComponents: false, // Setting it to true will make this component as external component. Keep it false if you want to consume only in context of FxP.
      componentFramework: ComponentFramework.React
    }

    const sharedComponentsInProject: ISharedComponents = {
      appName: 'SampleApplication', // Name of the application. Use the same name what you have used in main.ts file with registerPartnerApp method.
      components: [decoValidatorComponent, recoValidatorComponent, stateReversalComponent], // List of ISharedComponentConfig. Add all the ISharedComponentConfig objects here.
      disableSharing: false, // Turing it off will disable the sharing and your partners will not be able to consume the components.
      //sharedBundles: ['createreactsharedcomponent-bundle'] // Name of any shared bundle which you want to load with this component. Set empty array if you have none.
      sharedBundles: ['Pjm-React-Bundle']
    }
    return sharedComponentsInProject;
  }

  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    // const bulkMaintenanceEngagementRoute: IStateConfig = {
    //   name: "engagementBulkMaintenanceRequests",
    //   url: "/pjm/engagement/:wbsId/staffing/bulkMaintenance",
    //   params: new StateParams({
    //     resourceRequestIds: [],
    //     projectId: ""
    //   }),
    //   component: BulkMaintenanceContainer,
    //   componentFramework: "React",
    //   data: {
    //     headerName: "Bulk Maintenance Request",
    //     breadcrumbText: "Bulk Maintenance",
    //     pageTitle: "Bulk Maintenance"
    //   }
    // };

    const decoTestRoute: IStateConfig = {
      name: "DecoValidator",
      url: "/pjm/manageebs/deco",
      componentFramework: "React",
      data: {
        headerName: "Deco Validation Request",
        breadcrumbText: "Deco Validatior",
        pageTitle: "Deco Validation"
      }
    };

    const recoTestRoute: IStateConfig = {
      name: "RecoValidator",
      url: "/pjm/manageebs/reco",
      componentFramework: "React",
      data: {
        headerName: "Reco Validation Request",
        breadcrumbText: "Reco Validatior",
        pageTitle: "Reco Validation"
      }
    };

    const stateReversalChangeRoute: IStateConfig = {
      name: "StateReversalChange",
      url: "/pjm/manageebs/statereversal",
      componentFramework: "React",
      data: {
        headerName: "State Reversal Request",
        breadcrumbText: "State Reversal Change",
        pageTitle: "State Reversal Request"
      }
    };

    const engagementSummaryRoute: IStateConfig = {
      name: "engagementSummaryV3",
      url: "/pjm/engagement/:wbsId/summaryv3",
      params: new StateParams({
        projectId: "",
        view: PjmRoutes.EngagementSummary.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Summary",
        breadcrumbText: "Summary",
        pageTitle: "Summary"
      }
    };

    const projectSummaryRoute: IStateConfig = {
      name: "projectSummaryV3",
      url: "/pjm/project/:wbsId/summaryv3",
      params: new StateParams({
        projectId: "",
        view: PjmRoutes.ProjectSummary.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Summary",
        breadcrumbText: "Summary",
        pageTitle: "Summary"
      }
    };

    const staffingRoute: IStateConfig = {
      name: "staffingV2",
      url: "/pjm/engagement/:wbsId/staffingv2",
      params: new StateParams({
        projectId: "",
        view: PjmRoutes.EngagementStaffing.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Staffing",
        breadcrumbText: "Staffing",
        pageTitle: "Staffing"
      }
    };

    const engagementForecastRoute: IStateConfig = {
      name: "engagementForecast",
      url: "/pjm/engagement/:wbsId/forecast",
      params: new StateParams({
        projectId: "",
        view: PjmRoutes.EngagementPlanForecast.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Forecast",
        breadcrumbText: "Forecast",
        pageTitle: "Forecast"
      }
    };

    const engagementPlanForecastV2Route: IStateConfig = {
      name: "engagementPlanForecastV2",
      url: "/pjm/engagement/:wbsId/plan-forecastv2",
      params: new StateParams({
        projectId: "",
        view: PjmRoutes.EngagementPlanForecastV2.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Plan & Forecast",
        breadcrumbText: "Plan & Forecast",
        pageTitle: "Plan & Forecast"
      }
    };


    const projectForecastRoute: IStateConfig = {
      name: "projectForecast",
      url: "/pjm/project/:wbsId/forecast",
      params: new StateParams({
        projectId: "",
        view: PjmRoutes.ProjectPlanForecast.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Forecast",
        breadcrumbText: "Forecast",
        pageTitle: "Forecast"
      }
    };

    const projectPlanForecastV2Route: IStateConfig = {
      name: "projectPlanForecastV2",
      url: "/pjm/project/:wbsId/plan-forecastv2",
      params: new StateParams({
        projectId: "",
        view: PjmRoutes.ProjectPlanForecastV2.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Plan & Forecast",
        breadcrumbText: "Plan & Forecast",
        pageTitle: "Plan & Forecast"
      }
    };

    const myPortfolioRoute: IStateConfig = {
      name: "myPorfolio",
      url: "/portfolio",
      params: new StateParams({
        projectId: "",
        view: PjmRoutes.MyPortfolioHome.route
      }),
      componentFramework: "React",
      data: {}

    };

    const engagementFinancialChangeRequestFormRoute: IStateConfig = {
      name: PjmRoutes.EngagementFinancialChangeRequestForm.route,
      url: "/financials/change-request/create",
      sticky: true,
      params: new StateParams({
        hideNavigationBar: true,
        projectsThatFailedThresholdCheck: [],
        initializedFromPlanAndForecast: false,
        initializedFromNewForecastExperience: false,
        demandDiffData: [],
        view: PjmRoutes.EngagementFinancialChangeRequestForm.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Project Management",
        breadcrumbText: "Create Financial Change Request",
        pageTitle: "Create Financial Change Request",
        style: "icon icon-people",
        partnerTelemetryName: "ProjectManagement",
        pageNameForTelemetry: "CreateEngagementFinancialChangeRequestForm"
      }
    };

    const projectFinancialChangeRequestFormRoute: IStateConfig = {
      name: PjmRoutes.ProjectFinancialChangeRequestForm.route,
      url: "/financials/change-request/create",
      sticky: true,
      params: new StateParams({
        hideNavigationBar: true,
        projectsThatFailedThresholdCheck: [],
        initializedFromPlanAndForecast: false,
        initializedFromNewForecastExperience: false,
        demandDiffData: [],
        view: PjmRoutes.ProjectFinancialChangeRequestForm.route
      }),
      componentFramework: "React",
      data: {
        headerName: "Project Management",
        breadcrumbText: "Create Financial Change Request",
        pageTitle: "Create Financial Change Request",
        style: "icon icon-people",
        partnerTelemetryName: "ProjectManagement",
        pageNameForTelemetry: "CreateProjectFinancialChangeRequestForm"
      }
    };

    const routeInfo: IRouteInfo = {
      applicationName: "PJM-React",
      sharedBundles: ["Pjm-React-Bundle"],
      routes: [
        // bulkMaintenanceEngagementRoute,
        engagementSummaryRoute,
        projectSummaryRoute,
        staffingRoute,
        engagementForecastRoute,
        engagementPlanForecastV2Route,
        projectForecastRoute,
        projectPlanForecastV2Route,
        myPortfolioRoute,
        decoTestRoute,
        recoTestRoute,
        stateReversalChangeRoute,
        engagementFinancialChangeRequestFormRoute,
        projectFinancialChangeRequestFormRoute
      ]
    };

    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    const baseUrl = CommonUtils.getScriptBaseUrl()
    //const baseUrl = appSettings().cdnBaseUrl;
    const bundle: IPartnerBundle = {
      name: "Pjm-React-Bundle",
      files: [
        `${baseUrl}/vendor.bundle.js`,
        `${baseUrl}/styles.bundle.js`,
        `${baseUrl}/main.bundle.js`
      ],
      sequentialLoading: true
    };

    return [bundle];
  }
}

PartnerAppRegistrationService.registerLazyLoadedApp(SampleApplication_routes);
