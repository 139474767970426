import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";

export const prod: IPartnerAppSettings = {
  cdnBaseUrl: "https://pjmuiprod.azureedge.net/pjmux-react",
  serviceEndPoints: [
    {
      serviceEndPoint:
        "https://professionalservices.microsoft.com/lmt-coreapi/",
      clientId: "5eb170f9-f828-4121-936c-288eb43b050e"
    },
    {
      serviceEndPoint: "https://projectservices-api-prod.z01.azurefd.net/api/",
      clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
    },
    {
      serviceEndPoint: "https://forecastservice-prod.azurewebsites.net/",
      clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
    },
    {
      serviceEndPoint:
        "https://projectservices-api-prod.z01.azurefd.net/functions/api",
      clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
    },
    {
      serviceEndPoint: "https://esxp-prod.azurefd.net/grm",
      clientId: "72a9e05a-2c3c-47ae-bbb2-06ec753f307a"
    },
    {
      serviceEndPoint: "https://esxp-prod.azurefd.net/request",
      clientId: "72a9e05a-2c3c-47ae-bbb2-06ec753f307a"
    },
    {
      serviceEndPoint: "https://esxp-prod.azurefd.net/grm/rm/api",
      clientId: "72a9e05a-2c3c-47ae-bbb2-06ec753f307a"
    },
    {
      serviceEndPoint: "https://availabilityprod-eus.azurewebsites.net/api",
      clientId: "bc72c2f2-9689-42a0-aa49-f579509c867b"
    },
    {
      serviceEndPoint: "https://forecastfunctions-prod.azurewebsites.net/api/",
      clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
    },
    {
      serviceEndPoint: "https://projectservices-api-prod.z01.azurefd.net/functions/api",
      clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
    },
    {
      serviceEndPoint: "https://planservice-prod.azurewebsites.net/api/v1/",
      clientId: "83902542-ffa9-4272-9d09-31b93eccabfa"
    }
  ],
  PjmAppConfiguration: {
    projectServiceBaseUri:
      "https://projectservices-api-prod.z01.azurefd.net/api/",
    projectServiceForecastBaseUri:
      "https://forecastservice-prod.azurewebsites.net",
    projectServicePlanBaseUri: "https://planservice-prod.azurewebsites.net/api/v1/",
    forecastFunctionsBaseUri:
      "https://forecastfunctions-prod.azurewebsites.net/api/",
    projectServiceFunctionV2BaseUrl:
      "https://projectservices-api-prod.z01.azurefd.net/functions/api",
    projectServiceSubscriptionKey: "ed3cd6b9cd9b454ca93264c5b3351220",
    utilityApiPath: "dmutility/api",
    fluentUiCdn:
      "https://static2.sharepointonline.com/files/fabric/assets/icons/",
    fxpFlightingUrl:
      "https://flighting-api-preprod.azurefd.net/api/v1/evaluate?featureNames=QuotesAccess,OBOReadAccess,OBOReadWriteAccess,RenewalAccess",
    grmFedSearchLink: "https://esxp-prod.azurefd.net/grm/read/api/Search",
    grmBulkMaintenanceBaseUrl: "https://esxp-prod.azurefd.net/grm/",
    grmForecastBaseUrl: "https://availabilityprod-eus.azurewebsites.net/api",
    grmBaseUrl: "https://esxp-prod.azurefd.net/grm/rm/api",
    grmSubscriptionKey: "c31fa96910b347a49c03be14be329b71",
    grmForecastSubscriptionKey: "",
    laborManagementServiceBaseUri:
      "https://professionalservices.microsoft.com/lmt-coreapi/",
    laborManagementServiceSubscriptionKey: "b7f08ad447dd45f490a0f812eaef7bfc",
    oneProfileBaseUri: "https://oneprofileprodapipack.azurefd.net",
    fileSupportedFormatEarlyTerminationLetter: ".pdf , .docx, .msg",
    POCCValue: 10.00,
    ForecastClarityId: "k9bv7tdmxu"
  }
};
