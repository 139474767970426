import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";

export const dev: IPartnerAppSettings = {
  cdnBaseUrl: "http://localhost:5003",
  serviceEndPoints: [
    {
      serviceEndPoint: "https://o2cpmssit-project-api-0.azurewebsites.net/api/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint: "https://forecastservice-sit.azurewebsites.net/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint:
        "https://projectservice-functions-sit-v2.azurewebsites.net/api",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint: "https://esxp-sit.azurefd.net/grm",
      clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
    },
    {
      serviceEndPoint: "https://esxp-sit.azurefd.net/request",
      clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
    },
    {
      serviceEndPoint:
        "https://ps-cluster-aks-sit.westus.cloudapp.azure.com/grm/aurorasit",
      clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
    },
    {
      serviceEndPoint:
        "https://professionalservicesint.microsoft.com/gcm/grm/int/api",
      clientId: "38578c27-d821-45d3-95db-fcf0e8371e68"
    },
    {
      serviceEndPoint:
        "https://professionalservicesint.microsoft.com/Time2-SIT/",
      clientId: "5eb170f9-f828-4121-936c-288eb43b050e"
    },
    {
      serviceEndPoint: "https://esxp-sit.microsoft.com/rmavl/api",
      clientId: "6f40053e-5319-40e5-a90b-6f714506d96d"
    },
    {
      serviceEndPoint: "https://oneprofileuatapipack.azurefd.net/",
      clientId: "99b7fa0a-a125-4f7a-abea-efb429bb8972"
    },
    {
      serviceEndPoint: "https://forecastfunctions-sit.azurewebsites.net/api/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint: "https://plan-sit.azurewebsites.net/api/v1/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint: "https://projectservice-functions-sit-v2.azurewebsites.net/api",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    }
  ],
  PjmAppConfiguration: {
    projectServiceBaseUri:
      "https://o2cpmssit-project-api-0.azurewebsites.net/api/",
    projectServiceForecastBaseUri:
      "https://forecastservice-sit.azurewebsites.net",
    projectServicePlanBaseUri:
      "https://plan-sit.azurewebsites.net/api/v1/",
    forecastFunctionsBaseUri:
      "https://forecastfunctions-sit.azurewebsites.net/api/",
    projectServiceFunctionV2BaseUrl:
      "https://projectservice-functions-sit-v2.azurewebsites.net/api",
    projectServiceSubscriptionKey: "dc42c2d47e5d4162aa39d4114015717d",
    utilityApiPath: "dmutility/api",
    fluentUiCdn:
      "https://static2.sharepointonline.com/files/fabric/assets/icons/",
    fxpFlightingUrl:
      "https://flighting-api-preprod.azurefd.net/api/v1/evaluate?featureNames=QuotesAccess,OBOReadAccess,OBOReadWriteAccess,RenewalAccess",
    grmFedSearchLink: "https://esxp-sit.azurefd.net/grm/read/api/Search",
    grmBulkMaintenanceBaseUrl: "https://esxp-sit.azurefd.net/grm/",
    grmForecastBaseUrl: "https://esxp-sit.microsoft.com/rmavl/api",
    grmBaseUrl:
      "https://professionalservicesint.microsoft.com/gcm/grm/int/api",
    grmSubscriptionKey: "4f1ac558346d474facea88125516f6eb",
    grmForecastSubscriptionKey: "",
    laborManagementServiceBaseUri:
      "https://professionalservicesint.microsoft.com/lmt-coreapi-dev/",
    laborManagementServiceSubscriptionKey: "fd723c776fbd432fb12471dcf25d5c10",
    salesOrderServiceBaseUri:
      "https://professionalservicesint.microsoft.com/o2c/som/dev/api",
    salesOrderServiceSubscriptionKey: "55d23b2e2b20437c984d220e15f56722",
    oneProfileBaseUri: "https://oneprofileuatapipack.azurefd.net",
    fileSupportedFormatEarlyTerminationLetter: ".pdf , .docx, .msg",
    POCCValue: 10.00,
    ForecastClarityId: "k97j42f93y"
  }
};
